<div *ngIf="userProfile" class="header-user">
    
<!-- mobile -->
    <div class="overlay h-100 d-block bg-white closed-nav d-flex d-md-none" id="myProfile">
        <div class="navbar-nav d-flex flex-column h-100 w-100">
            <div class="d-flex flex-row d-md-none cp-ms-20">
                <button class="bg-transparent border-0 c-text-28 cp-pt-20" (click)="closeMyProfile()">&times;</button>
            </div>
            <ul [isAnimated]="true" class="w-100 p-2 mt-2">
            <div *ngIf="representatives && representatives.Total > 1">
                <div class="text-start cp-ps-16">
                    <span translate class="text-muted">sidemenu.onBehalfOn</span>
                </div>
                <div *ngFor="let item of representatives.Data">
                    <li class="py-2 px-3" [class.active]="item.Active">
                        <a title="{{item.AccountName}}" (click)='ChangeContext(item.Id, true)'
                            class="profile d-flex flex-row align-items-center" [class.active]="item.Active">
                            <div class="urp-icon" [ngClass]="{'urp-icon-radio-button c-icon-grey': !item.Active, 'urp-icon-radio-button-chacked c-icon-primary': item.Active}"></div>
                            <span class="ms-3 d-block text-truncate c-text-14 cp-pb-4">{{item.AccountName}}</span>
                        </a>
                    </li>
                </div>
            </div>
            <hr class="my-1">
            <li class="py-2 px-3"><a title="{{ 'sidemenu.profile' | translate}}" (click)='Profile()' class="profile d-flex flex-row align-items-center">
                    <div class="urp-icon urp-icon-profile c-icon-grey"></div><span translate
                        class="ms-3 d-block text-truncate c-text-14">sidemenu.profile</span>
                </a></li>
            <hr class="my-1">
            <li class="py-2 px-3"><a title="{{ 'sidemenu.logout' | translate}}" (click)='Logout()' class="logout d-flex flex-row align-items-center">
                    <div class="urp-icon urp-icon-log-out c-icon-grey"></div><span translate
                        class="ms-3 d-block text-truncate c-text-14">sidemenu.logout</span>
                </a></li>
        </ul>



        </div>
    </div>
    <!--  -->
    
    <button (click)="showUserMenu()" [ngClass]="{active: userMenuShown}"
        class="btn header-user-btn mx-3 border-0 mt-1 mb-1 d-none d-lg-flex flex-row align-items-center rounded"
        role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
        <div *ngIf="userInfo" class="text-end mx-2 py-1">
            <span>{{userInfo?.fullName}}</span>
            <br />
            <div *ngIf="representatives && representatives.Total > 1">
                <span translate class="text-muted">sidemenu.onBehalfOn</span>
                <!-- TODO need to know which representation active -->
                <span class="fw-bold"> {{representative.AccountName}}</span>
            </div>
        </div>
        <div id="chevron" class="urp-icon urp-icon-user-chevron-down"></div>
    </button>
    <button (click)="openMyProfile()" 
        class="btn d-flex d-lg-none border-0" aria-label="User icon" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
        aria-expanded="false">
        <i class="pt-1 c-i-user-1 c-icon c-icon-20 c-icon-darker-grey"></i>
    </button>
    
    <ul *ngIf="userInfo" [collapse]="!userMenuShown" [isAnimated]="true" class="dropdown-menu w-100 p-2 mt-2"
        aria-labelledby="dropdownMenuLink">
        <!-- <span class="text-muted">On behalf of:</span>
        <li class="px-3 py-2"><a class="d-flex flex-row" href="#"><div class="c-icon c-cb-circle-default"></div><span class="ms-3 d-block text-truncate">{{userInfo.fullName}}</span></a></li> -->
        <div *ngIf="representatives && representatives.Total > 1">
            <span translate class="text-muted">sidemenu.onBehalfOn</span>
            <div *ngFor="let item of representatives.Data">
                <li class="py-2 px-3" [class.active]="item.Active">
                    <a title="{{item.AccountName}}" (click)='ChangeContext(item.Id, false)'
                        class="profile d-flex flex-row align-items-center" [class.active]="item.Active">
                        <div class="urp-icon" [ngClass]="{'urp-icon-radio-button c-icon-grey': !item.Active, 'urp-icon-radio-button-chacked c-icon-primary': item.Active}"></div>
                        <span class="ms-3 d-block text-truncate c-text-14 cp-pb-4">{{item.AccountName}}</span>
                    </a>
                </li>
            </div>
        </div>
        <hr class="my-1">
        <li class="py-2 px-3"><a title="{{ 'sidemenu.profile' | translate}}" (click)='Profile()' class="profile d-flex flex-row align-items-center">
                <div class="urp-icon urp-icon-profile c-icon-grey"></div><span translate
                    class="ms-3 d-block text-truncate c-text-14">sidemenu.profile</span>
            </a></li>
        <hr class="my-1">
        <li class="py-2 px-3"><a title="{{ 'sidemenu.logout' | translate}}" (click)='Logout()' class="logout d-flex flex-row align-items-center">
                <div class="urp-icon urp-icon-log-out c-icon-grey"></div><span translate
                    class="ms-3 d-block text-truncate c-text-14">sidemenu.logout</span>
            </a></li>
    </ul>
</div>
import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';

// keycloak
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './utility/keycloak.init';

// formio
import { Formio, FormioAppConfig } from '@formio/angular';
import { FormioGrid } from '@formio/angular/grid';

// pipes
import { FormatDatePipe } from './pipes/format-date.pipe';

// service
import { SessionStateService } from './services/session-state-service/session-state.service';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header-main/header.component';
import { UserListComponent } from './components/userManagement/user/user-list/user-list.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { ListSkeletonComponent } from './components/shared/loader/list-skeleton/list-skeleton.component';
import { HomeComponent } from './components/layout/home/home.component';
import { DataTableComponent, NgbdSortableHeader } from '../app/components/shared/data-table/data-table.component';
import { DomainListComponent } from './components/systemConfiguration/domain/domain-list/domain-list.component';
import { ClassifierListComponent } from './components/systemConfiguration/classifier/classifier-list/classifier-list.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar-main/sidebar.component';
import { SidebarLogoComponent } from './components/layout/sidebar/sidebar-logo/sidebar-logo.component';
import { SidebarLangComponent } from './components/layout/sidebar/sidebar-lang/sidebar-lang.component';
import { SidebarMenuComponent } from './components/layout/sidebar/sidebar-menu/sidebar-menu.component';
import { SidebarEnvComponent } from './components/layout/sidebar/sidebar-env/sidebar-env.component';
import { HeaderEnvComponent } from './components/layout/header/header-env/header-env.component';
import { HeaderUserComponent } from './components/layout/header/header-user/header-user.component';
import { HeaderNotificationsComponent } from './components/layout/header/header-notifications/header-notifications.component';
import { PaggingComponent } from './components/shared/pagging/pagging.component';
import { BreadcrumbComponent } from './components/shared/breadcrumb/breadcrumb.component';
import { CustomToast } from './components/shared/custom-toast/custom-toast.component';
import { InstitutionListComponent } from './components/systemConfiguration/institution/institution-list/institution-list.component';
import { RolesListComponent } from './components/userManagement/roles/roles-list/roles-list.component';
import { RegisterListComponent } from './components/systemConfiguration/register/register-list/register-list.component';
import { HelperService } from './services/helper.service/helper.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ToastrModule } from 'ngx-toastr';
import { ModalComponent } from './components/shared/modal/modal.component';
import { BaseFilterComponent } from './components/shared/base-filter/base-filter.component';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateLoaderService } from './services/translate-loader.service';
import { LocalStorageService } from './services/storage.service/local-storage.service';
import { SelectComponent } from './components/shared/select/select.component';
import { SelectOptionsTranslatePipe } from './pipes/select-options-translate.pipe';
import { DynamicTranslatePipe } from './pipes/custom-translate.pipe';
import { LanguageListComponent } from './components/systemConfiguration/language/language-list/language-list.component';
import { TranslationListComponent } from './components/systemConfiguration/translation/translation-list/translation-list.component';
import { MessageTemplateListComponent } from './components/systemConfiguration/messageTemplates/message-template-list/message-template-list.component';
import { MessageTemplateEditorComponent } from './components/systemConfiguration/messageTemplates/message-template-editor/message-template-editor.component';
import { MessageTemplateViewerComponent } from './components/systemConfiguration/messageTemplates/message-template-viewer/message-template-viewer.component';
import { KeycloakIdTokenInterceptor } from './interceptors/keycloak-idtoken.interceptor';
import { NrdFormioModule } from 'projects/nrd-formio/src/app/app.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AppConfigService } from './services/app-config.service';
import { UserSessionTimeoutComponent } from './popups/user-session-timeout/user-session-timeout.component';
import { SessionModalComponent } from './popups/session-modal/session-modal.component';
import { BaseFormComponent } from './components/shared/base-form/base-form.component';
import { UserFormComponent } from './components/userManagement/user/user-form/user-form.component';
import { RolesFormComponent } from './components/userManagement/roles/roles-form/roles-form.component';
import { APP_CONFIG } from './utility/tokens';
import { ApplicationsListComponent } from './components/register/applications-list/applications-list.component';
import { ApplicationFormComponent } from './components/register/application-form/application-form.component';
import { ReassignFormComponent }  from './components/register/reassign-form/reassign-form.component'
import { ModalStackService } from './services/modal-service/modal-stack.service';
import { RegisterComponent } from './components/register/register.component';
import { RegisterFunctionalitySelectorComponent } from './components/register/register-functionality-selector/register-functionality-selector.component';
import { RegisterServicesListComponent } from './components/register/services-list/register-services-list.component';
import { RegistrationObjectListComponent } from './components/register/registration-objects-list/registration-objects-list.component';
import { RegistrationObjectFormComponent } from './components/register/registration-object-form/registration-object-form.component';
import { RegisterService } from './services/register.service/register.service';
import { DynamicListComponent } from './components/shared/dynamic-list/dynamic-list.component';
import { PaggingGridComponent } from './components/shared/pagging/pagging-grid.component';
import { DataTableGridComponent } from './components/shared/data-table-grid/data-table-grid.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table'
import { EventNotificationConfigComponent } from './components/systemConfiguration/eventNotificationConfiguration/eventNotificationConfigurationEditor/event-notification-config.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RegistrationObjectFileListComponent } from './components/register/registration-object-files-list/registration-object-files-list.component';
import { RegistrationObjectHistoryListComponent } from './components/register/registration-object-history-list/registration-object-history-list.component';
import { NotificationViewerComponent } from './components/misc/notificationViewer/notification-viewer.component'
import { MatBadgeModule } from '@angular/material/badge';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { PushNotificationListComponent } from './components/misc/pushNotificationList/push-notification-list.component';
import { PushNotificationDataTableComponent } from './components/misc/pushNotificationList/pushNotificationDataTable/push-notification-data-table.component';
import { EventNotificationConfigListComponent } from './components/systemConfiguration/eventNotificationConfiguration/eventNotificationConfigList/event-notification-config-list.component';
import { MyOfficeComponent } from './components/myOffice/my-office/my-office.component';
import { SubmittedApplicationsComponent } from './components/myOffice/submitted-applications/submitted-applications.component';
import { IncompleteApplicationsComponent } from './components/myOffice/incomplete-applications/incomplete-applications.component';
import { RegisterServicesDetailsComponent } from './components/register/service-details/register-service-details.component';
import { RegisterServiceService } from './services/register-service.service/register-service.service';
import { RegisterServiceListComponent } from './components/systemConfiguration/register-service/register-service-list/register-service-list.component';
import { IdGenerationConfigListComponent } from './components/systemConfiguration/idGenerationConfigs/id-generation-config-list/id-generation-config-list.component';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { MenuService } from './services/menu.service/menu.service';
import { NavigationGuardService } from './utility/navigation.guard';
import { PageLeaveModalComponent } from './components/shared/page-leave-modal/page-leave-modal.component';
import { RegistrationObjectHistoryFormComponent } from './components/register/registration-object-history-form/registration-object-history-form.component';
import { CronJobConfigurationListComponent } from './components/systemConfiguration/cronJobConfiguration/cron-job-configuration-list.component';
import { RegisterFunctionalityListComponent } from './components/systemConfiguration/register-functionality/register-functionality-list.component';
import { RouterOutletPlaceholder } from './components/shared/router-outlet-placeholder/router-outlet-placeholder.component';
import { TasksListComponent } from './components/register/tasks-list/tasks-list.component';
import { ThumbnailComponent } from './components/shared/thumbnail/thumbnail.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MyOfficeRegistrationObjectsListComponent } from './components/myOffice/my-office-registration-objects-list/my-office-registration-objects-list.component';
import { FilterButtonsComponent } from './components/shared/filter-buttons/filter-buttons.component';
import { ExternalClientListComponent } from './components/systemConfiguration/externalClients/external-client-list/external-client-list.component';
import { MyProfileComponent } from './components/userManagement/my-profile/my-profile.component';
import { PrintoutQRCodeVerificationComponent } from './components/public/printout-qr-code-verification/printout-qr-code-verification.component';
import { HeaderPublicComponent } from './components/layout/header/header-main-public/header-main-public.component';
import { SidebarSecondComponent } from './components/layout/sidebar/sidebar-second/sidebar-second.component';
import { AccountTypeListComponent } from './components/systemConfiguration/account-type/account-type-list/account-type-list.component';
import { AccountListComponent } from './components/userManagement/accounts/account-list/account-list.component';
import { RegisterServiceComponent } from './components/systemConfiguration/register-service/register-service-view/register-service.component';
import { RegisterServiceGeneralInfoComponent } from './components/systemConfiguration/register-service/register-service-general-info/register-service-general-info.component';
import { RegisterServiceWorkflowComponent } from './components/systemConfiguration/register-service/register-service-workflow/register-service-workflow.component';
import { RegisterServiceConfigurationComponent } from './components/systemConfiguration/register-service/register-service-configuration/register-service-configuration.component';
import { RegisterServiceWorkflowConfigurationComponent } from './components/systemConfiguration/register-service/register-service-workflow-configuration/register-service-workflow-configuration.component';
import { RegisterServiceObjectLoadAndCleanUpConfigurationComponent } from './components/systemConfiguration/register-service/register-service-object-load-and-clean-up-configuration/register-service-object-load-and-clean-up-configuration.component';
import { SMSProviderConfigComponent } from './components/systemConfiguration/sms-provider-config/sms-provider-config.component';
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { LoadingStateService } from './services/loading-state.service/loading-state.service';
import { RepresentativeListComponent } from './components/userManagement/representatives/representative-list/representative-list.component';
import { MyOfficeRepresentativesListComponent } from './components/myOffice/my-office-representatives-list/my-office-representatives-list.component';
import { ContextService } from './services/context.service/context.service';
import { RepresentativeFormComponent } from './components/userManagement/representatives/representative-form/representative-form.component';
import { NameSimilarityListComponent } from './components/name-similarity-list/name-similarity-list.component';
import { SessionStorageService } from './services/storage.service/session-storage.service';
import { PublicSearchComponent } from './components/public/public-search/public-search.component';
import { NationalHolidayListComponent } from './components/systemConfiguration/national-holiday/national-holiday-list/national-holiday-list.component';



// TODO: TPL patikrinti ar viskas ok. Old (Formio as any).icons
Formio['icons'] = 'fontawesome';

@NgModule({
  declarations: [
    AppComponent,
    FormatDatePipe,
    HeaderNotificationsComponent,
    HeaderComponent,
    HeaderPublicComponent,
    UserListComponent,
    LoaderComponent,
    ListSkeletonComponent,
    BaseFormComponent,
    UserFormComponent,
    RolesFormComponent,
    HomeComponent,
    NgbdSortableHeader,
    DataTableComponent,
    DomainListComponent,
    ClassifierListComponent,
    SidebarComponent,
    SidebarSecondComponent,
    SidebarLogoComponent,
    SidebarLangComponent,
    SidebarMenuComponent,
    HeaderEnvComponent,
    SidebarEnvComponent,
    HeaderUserComponent,
    PaggingComponent,
    PaggingGridComponent,
    BreadcrumbComponent,
    InstitutionListComponent,
    RolesListComponent,
    CustomToast,
    RegisterListComponent,
    ModalComponent,
    PageLeaveModalComponent,
    BaseFilterComponent,
    FilterButtonsComponent,
    SelectComponent,
    SelectOptionsTranslatePipe,
    DynamicTranslatePipe,
    LanguageListComponent,
    TranslationListComponent,
    MessageTemplateListComponent,
    MessageTemplateEditorComponent,
    MessageTemplateViewerComponent,
    RegistrationObjectFileListComponent,
    RegisterComponent,
    RegisterFunctionalitySelectorComponent,
    RouterOutletPlaceholder,
    RegisterServicesListComponent,
    RegistrationObjectListComponent,
    TasksListComponent, 
    UserSessionTimeoutComponent,
    SessionModalComponent,
    ApplicationsListComponent,
    RegistrationObjectFormComponent,
    RegistrationObjectHistoryFormComponent,
    ApplicationFormComponent,
    ReassignFormComponent,
    DynamicListComponent,
    DataTableGridComponent,
    NotificationViewerComponent,
    PushNotificationListComponent,
    PushNotificationDataTableComponent,
    RegistrationObjectHistoryListComponent,
    EventNotificationConfigComponent,
    EventNotificationConfigListComponent,
    MyOfficeComponent,
    SubmittedApplicationsComponent,
    IncompleteApplicationsComponent,
    RegisterServicesDetailsComponent,
    RegisterServiceListComponent,
    IdGenerationConfigListComponent,
    CronJobConfigurationListComponent,
    RegisterFunctionalityListComponent,
    MyOfficeRegistrationObjectsListComponent,
    ExternalClientListComponent,
    ThumbnailComponent,
    MyProfileComponent,
    PrintoutQRCodeVerificationComponent,
    AccountTypeListComponent,
    AccountListComponent,
    SMSProviderConfigComponent,
    RegisterServiceComponent,
    RegisterServiceGeneralInfoComponent,
    RegisterServiceWorkflowComponent,
    RegisterServiceConfigurationComponent,
    RegisterServiceWorkflowConfigurationComponent,
    RegisterServiceObjectLoadAndCleanUpConfigurationComponent,
    RepresentativeListComponent,
    MyOfficeRepresentativesListComponent,
    RepresentativeFormComponent,
    NameSimilarityListComponent,
    PublicSearchComponent,
    NationalHolidayListComponent
  ],
  imports: [
    MatTabsModule,
    MatTableModule,
    MatBadgeModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatSelectModule,
    HttpClientModule,
    AngularEditorModule,
    BrowserModule,
    AppRoutingModule,
    NgxSkeletonLoaderModule,
    KeycloakAngularModule,
    FormsModule,    
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    SelectDropDownModule,
    NrdFormioModule,
    FormioGrid,
    PaginationModule,
    ToastrModule.forRoot({
      toastComponent: CustomToast,
      toastClass: ''
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'EN',
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLoaderService,
        deps: [HttpClient, LocalStorageService, KeycloakService, APP_CONFIG]
      }
    }),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    MatTooltipModule
  ],
  entryComponents: [
    CustomToast
  ],
  providers: [
    AppConfigService,
    ContextService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AppConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakIdTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    },
    { provide: APP_CONFIG, 
      deps: [AppConfigService],
      useFactory: (appConfigSvc: AppConfigService) => {
        return appConfigSvc.getConfig();
      }
    },
    SessionStateService,
    ModalStackService,
    LocalStorageService,
    SessionStorageService,
    HelperService,
    {
      provide: FormioAppConfig,
      deps: [AppConfigService],
      useFactory: (appConfigSvc: AppConfigService) => {
        return appConfigSvc.getConfig().api.base;
      }
    },
    DatePipe,
    TranslatePipe,
    RegisterService,
    RegisterServiceService,
    NavigationGuardService,
    MenuService,
    LoadingStateService,
    {
      provide: MatPaginatorIntl,
      useClass: PaggingComponent
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

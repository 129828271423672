import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { KeycloakService } from "keycloak-angular";
import { BehaviorSubject } from "rxjs";
import { IConfig } from "../../models/config.interface";
import { APP_CONFIG } from "../../utility/tokens";
import { SessionStorageService } from "../storage.service/session-storage.service";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class ContextService {
    constructor(
        protected readonly keycloak: KeycloakService,
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private readonly environment: IConfig,
        private storageService: SessionStorageService,
        private router: Router) { }

    representatives = new BehaviorSubject<any>(null);
    representativeId = new BehaviorSubject<any>(null);

    representations = this.representatives.asObservable();
    representationId = this.representativeId.asObservable();

    initContext(): void {
        void this.keycloak.getKeycloakInstance().loadUserInfo().then(user => {
            this.httpClient.get(`${this.environment.api.umRepresentatives}?UserIds=${user['userId']}&IsValid=true`).subscribe(res => {

                this.representatives.next(res);
                this.storageService.store(this.storageService.KEY_REPRESENTATIONS, res);

                let id: Guid = null; // TODO need to crete window to select representation on login
                if (this.storageService.exists(this.storageService.KEY_REPRESENTATION_ID)) {
                    id = this.storageService.retrieve(this.storageService.KEY_REPRESENTATION_ID);
                } else {
                    id = res['Data'][0].Id;
                    if (res['Data'].filter(q => q.AccountName == q.UserFullName)[0]) {
                        id = res['Data'].filter(q => q.AccountName == q.UserFullName)[0].Id;
                    }
                    this.storageService.store(this.storageService.KEY_REPRESENTATION_ID, id);
                }

                this.representativeId.next(id);
            });
        });
    }

    changeContext(id: Guid): void {
        this.representativeId.next(id);
        this.storageService.store(this.storageService.KEY_REPRESENTATION_ID, id);

        let urlTree = this.router.parseUrl(this.router.url);
        let refreshIndex = +urlTree.queryParams?.refreshRouting || 0; // workaround to change query params for page refresh
        const queryParams = Object.assign({}, urlTree.queryParams, { refreshRouting: refreshIndex + 1 });
        urlTree.queryParams = {};

        this.router.navigate([urlTree.toString()], {
            queryParams: queryParams,
            replaceUrl: true
        });
    }
}
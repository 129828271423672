import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { routesEnum } from '../../../enumerators/routesEnum';
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { HttpService } from '../../../services/http-service/http.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TableColumn } from 'projects/urp/src/app/models/table-column.model';
import { Location } from '@angular/common';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { AppConfigService } from 'projects/urp/src/app/services/app-config.service';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { LocalStorageService } from 'projects/urp/src/app/services/storage.service/local-storage.service';
import { SubSystem } from '../../../enumerators/classifier.enum';
import { TranslateService } from '@ngx-translate/core';
import { Formio } from 'formiojs';
import { Subscription } from 'rxjs';
import { FormioBaseComponent, FormioComponent } from '@formio/angular';
import { ObjectHelpers } from 'projects/core/src/lib/shared/helpers/object-helpers';


@Component({
  selector: 'app-dynamic-list',
  templateUrl: './dynamic-list.component.html',
  styleUrls: ['./dynamic-list.component.scss']
})
export class DynamicListComponent extends BaseListComponent implements OnInit, OnDestroy, OnChanges {

  @Input() listingMongoId: string;
  @Input() module: SubSystem;
  @Input() filterHeader: string;
  @Input() actions: string[] = ['view'];
  @Input() btnAddNewTitle: string = null;
  @Input() staticFilter = false;
  @Input() showFilterButton = true;
  @Input() autoRefreshList = false;
  @Input() listingApiUrl: string;

  @Output() create: EventEmitter<any>;

  language = new EventEmitter<string>();
  addTranslations = new EventEmitter<any>();
  refreshForm = new EventEmitter();
  defaultSearchData = null; //set in loadListingFormParameters
  searchData = null; //set in loadListingFormParameters
  languageSubscription: Subscription;
  @ViewChild('formio') formioComponent: FormioComponent;
  @ViewChild('appDataTableDiv') appDataTableElement: ElementRef;
  cachedLanguages = {};

  listUrl = null; // set in defineParameters
  formId = null; // set in defineParameters

  tableColumns = [
    new TableColumn(null, null, 'action')
  ];

  listingFormJson: any;
  formOptions: any;
  formSubmission: any = {};

  query: any = {};

  paramsSubscription: Subscription;

  constructor(protected toastrService: ToastrServiceExt,
    protected modal: BsModalService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected readonly keycloak: KeycloakService,
    protected location: Location,
    protected httpSerice: HttpService,
    private appConfigService: AppConfigService,
    protected storage: LocalStorageService,
    protected helper: HelperService,
    @Inject(APP_CONFIG) private readonly environment: IConfig,
    protected translateService: TranslateService
  ) {
    super(toastrService, modal, router, activatedRoute, keycloak, location, httpSerice);
    this.getOptions();
    this.create = new EventEmitter<any>();

  }

  ngOnInit(): void {
    this.languageSubscription = this.translateService.onLangChange.subscribe(() => {
      if (this.formioComponent)
        this.addLanguage(this.getCurrentLanguage());
    });

    this.paramsSubscription = this.activatedRoute.queryParamMap.subscribe(queryParams => {
      this.query = queryParams;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.defineParameters(this.module);
    this.loadListing(this.listingMongoId);
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }

  getCurrentLanguage(): string {
    return this.translateService.currentLang;
  }

  addLanguage(lang: string): void {
    if (!this.cachedLanguages[lang.toLowerCase()]) {
      this.formioComponent.formio.addLanguage(lang.toLowerCase(), this.storage.retrieve(`translations_${lang}`));
      this.cachedLanguages[lang.toLowerCase()] = true;
    }
    this.language.emit(lang.toLowerCase());
  }

  private defineParameters(module: SubSystem) {
    switch (module) {
      case SubSystem.APP:
        this.apiUrl = this.environment.api.appDynamicList;
        this.listUrl = routesEnum.registerApplicationsList.url;
        break;
      case SubSystem.REG:
        this.apiUrl = this.environment.api.regDynamicList;
        this.listUrl = routesEnum.registerRegistersList.url;
        break;
      case SubSystem.UM:
        this.apiUrl = this.environment.api.umDynamicList;
        this.listUrl = routesEnum.userList.url;
        break;
      case SubSystem.PUB:
        this.apiUrl = this.environment.api.pubDynamicList;
        this.listUrl = routesEnum.publicSearch.url;
        break;
      default:
        throw new Error(`Module ${module} is not yet supported`);
    }
  }

  protected loadListing(listingMongoId: string): void {
    this.loading = true;
    this.apiService.get(this.apiUrl + '/GetListingParameters?listingMongoId=' + listingMongoId).subscribe((res: any) => {
      const resJson = JSON.parse(res);

      this.listingFormJson = resJson.form;
      this.tableColumns = [
        new TableColumn(null, null, 'action')
      ];
      for (const e of resJson.columns) {
        this.tableColumns.splice(this.tableColumns.length - 1, 0, new TableColumn(e.key, e.label, e.type, e.classifierTranslationKey));
      }

      this.formId = resJson.refFormId;
      this.defaultSearchData = resJson.defaultSearchData;
      this.searchData = Object.assign({}, this.defaultSearchData);
      super.ngOnInit();

      this.loadList(false);
    }, (err) => {
      this.loading = false;
      this.toastr.serverError(err);
    })
  }

  getOptions(): void {
    const lang = this.getCurrentLanguage();
    this.formOptions = {
      template: 'bootstrap5',
      disableAlerts: true,
      noAlerts: true,
      language: lang.toLowerCase(),
      i18n: {
        [lang.toLowerCase()]: this.storage.retrieve(`translations_${lang}`)
      }
    }
    this.cachedLanguages[lang.toLowerCase()] = true;
  }

  public formioCustomEvent(event: any): void {
    const mergedObject = {
      ...this.searchData,
      ...event.data
    };
    switch (event.type) {
      case 'filter':
        this.searchData = mergedObject;
        this.onFilter();
        break;
      case 'clear':
        this.clearFilter();
        break;
      case 'Back':
        this.location.back();
        break;
      default:
        alert(`Event ${event.type} is not handled for this form yet.`);
    }
  }
  protected override onFilter(scrollIntoView = false) {
    this.loadList();

    if (scrollIntoView) {
      this.appDataTableElement.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }

  public formioChange(event: any): void {
  if (event.changed) {
      if (JSON.stringify(event.metadata) == JSON.stringify({})) {
        for (const key of this.query.keys ?? []) {
          const values = this.query.getAll(key);
          if (values.length === 1) {
            this.searchData[key] = values.shift();
          }
          else {
            this.searchData[key] = values;
          }
        }
  
        this.refreshForm.emit({
          submission: {
            data: {
              filter: ObjectHelpers.unflatten(this.searchData)
            }
          }
        });
      }
  
      if (event.data?.filter) {
        const mergedObject = {
          ...this.searchData,
          ...ObjectHelpers.flatten(event.data.filter)
        };
        this.searchData = mergedObject;
      }
  
      if (this.autoRefreshList) {
        this.loadList();
      }
    }
    
  }

  render(): void {
    const lang = this.getCurrentLanguage();
    this.language.emit(lang.toLowerCase());
  }

  protected override clearFilter(): void {
    this.searchData = Object.assign({}, this.defaultSearchData, { RegisterId: this.searchData['RegisterId'] });
    this.refreshForm.emit({
      submission: {
        data: {
          filter: ObjectHelpers.unflatten(this.defaultSearchData)
        }
      }
    });
    this.loadList();
  }

  protected override onView(item: any): void {
    void this.router.navigate([routesEnum.form.url], { relativeTo: this.activatedRoute, queryParams: this.createQueryParams ?? { FormId: item.__view_form_id, RegisterId: this.searchData['RegisterId'], Id: item.__raw_data_id, readOnly: true } });
  }

  protected override onEdit(item: any): void {
    void this.router.navigate([routesEnum.form.url], { relativeTo: this.activatedRoute, queryParams: this.createQueryParams ?? { FormId: item.__view_form_id, RegisterId: this.searchData['RegisterId'], Id: item.__raw_data_id } });
  }

  protected onDelete(item: any): void {
    this.openDeleteConfirmationModal();

    this.modalRef.content.clickEvent.subscribe(res => {
      if (res.okEvent) {
        this.apiService.delete(`${this.listingApiUrl}/${item.__raw_data_id}`).subscribe(() => {
          this.loadList();
          this.toastr.success('Item deleted');
        }, (err) => {
          this.toastr.serverError(err);
        });
      }
    });
  }

  protected override onCreate(): void {
    this.create.emit();
  }

}

import { MenuService } from "../../../services/menu.service/menu.service";

export class BaseMenuComponent {
    constructor(protected menuService: MenuService) { }
    isObjectEmpty(obj) {
        return (!obj || Object.keys(obj).length === 0) ? true:false;
    }

    minSideMenu() {
        var display = "none";
        var displayAppear = "block";
        document.getElementById("c-retractable").style.maxWidth = "65px";
        document.getElementById("c-retractable").style.minWidth = "65px";
        document.getElementById("c-retractable").style.width = "auto";
        this.setStyle(display, displayAppear, false);
    }
    maxSideMenu() {
        var display = "block";
        var displayAppear = "none";
        document.getElementById("c-retractable").style.maxWidth = "300px";
        document.getElementById("c-retractable").style.minWidth = "300px";
        document.getElementById("c-retractable").style.width = "300px";
        this.setStyle(display, displayAppear, true);
    }
    setStyle(display, displayAppear, stateOfMenu) {
        var targetDivs = document.getElementById("c-retractable").getElementsByClassName("collapseDisappear");
        var targetDivsAppear = document.getElementById("c-retractable").getElementsByClassName("collapseAppear");
        let i = 0;
        while(i < targetDivs.length){
            targetDivs[i]['style'].display = display;
            i++;
        }
        i = 0;
        while(i < targetDivsAppear.length){
          targetDivsAppear[i]['style'].display = displayAppear;
          i++;
        }
        this.menuService.showSidebarEvent.next(stateOfMenu);
    }
    closeMyNav(){
        document.body.style.height = "auto";
        document.body.style.overflow = "auto";
        document.getElementById('myNav').classList.remove('opened-nav');
        document.getElementById('myNav').classList.add('closed-nav');
    }
    openMyNav(){
        document.body.style.height = "100vh";
        document.body.style.overflow = "hidden";
        document.getElementById('myNav').classList.remove('closed-nav');
        document.getElementById('myNav').classList.add('opened-nav');
    }
    closeMyProfile(){
        document.body.style.height = "auto";
        document.body.style.overflow = "auto";
        document.getElementById('myProfile').classList.remove('opened-nav');
        document.getElementById('myProfile').classList.add('closed-nav');
    }
    openMyProfile(){
        document.body.style.height = "100vh";
        document.body.style.overflow = "hidden";
        document.getElementById('myProfile').classList.remove('closed-nav');
        document.getElementById('myProfile').classList.add('opened-nav');
    }
}
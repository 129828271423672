import { Formio } from '@formio/angular';

export const PluginHooks = {
    priority: 0,
    // init(formio): void {
    // },
    preRequest(requestArgs): void {
        const token = Formio.getToken();
        const user = Formio.getUser();
		
		requestArgs.opts = !!requestArgs.opts ? requestArgs.opts : {};

        requestArgs.opts.header = requestArgs.opts.header || new Headers();

        if (token) {
            requestArgs.opts.header.append('Authorization', 'Bearer ' + Formio.getToken());
        }

        if (user?.representationId) {
            requestArgs.opts.header.append('RepresentationId', user.representationId);
        }

        requestArgs.opts.noToken = true;
    }
    // request(requestArgs): void {
    // },
    // wrapRequestPromise(promise, requestArgs): any {
    //     return promise;
    // },
    // preStaticRequest(requestArgs): void {
    // },
    // staticRequest(requestArgs): void {
    // },
    // wrapStaticRequestPromise(promise, requestArgs): any {
    //     return promise;
    // }
};

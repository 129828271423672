<div *ngIf="loading" class="container-fluid">
    <app-loader type="list" ></app-loader>
  </div>
  
  <div *ngIf="!loading">
    <app-breadcrumb [isHeader]="true"></app-breadcrumb>
    <div class="bg-secondary px-3">
      <div [ngClass]="{'filter-closed': isFilterClosed, 'pt-3': !isFilterClosed}">
        <app-filter-buttons (clickClearFilter)="clearFilter()" (clickCloseFilter)="closeFilter()">
        </app-filter-buttons> 
      </div>
      <div [ngClass]="{'filter-closed': isFilterClosed, 'd-none': isFilterClosed}">
        <div class="justify-content-center">
            <formio class="formio" #formio [form]="form" [language]="language" [submission]="formSubmission"
                [options]="formOptions" (customEvent)="formioCustomEvent($event)" (change)="formioChange($event)"
                [refresh]="refreshForm" (render)="render()">
            </formio>
        </div>
      </div>
      <div class="form-group pb-3" [ngClass]="{'filter-closed': isFilterClosed, 'd-none': isFilterClosed}">
        <a translate class="btn btn-primary cp-font-weight-700"
          (click)="onFilter()">pages.common.list.searchButton
        </a>
    </div>
</div>

<input #fileInput type="file" (change)="onFileSelected($event)" accept=".json" style="display: none;">

<ng-container>
<div class="table-holder">
    <div class="container-fluid c-max-width-755px">
        <div class="row">
            <div class="col-md-10 d-flex col-sm-12 col-xs-12">    
                <div class="display-table-100">
                    <div class="table-cell-align-vertical-midle cp-pb-8">
                        <button class="btn btn-md bg bg-transparent icon-color-primary icon-center p-0 border-0 btn-info text-decoration-none" (click)="backClicked()">
                            <span class="fa-solid fa-arrow-left fa-2x cp-text-primary"></span>
                        </button>
                    </div>
                    <div class="table-cell-align-vertical-midle">
                        <div class="d-flex">
                            <h4 translate>pages.registerFilesList.title</h4>&nbsp;
                            <h4>{{this.RegistrationNumberExact}}</h4>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div *ngIf="isFilterClosed" class="col-md-2 col-sm-12 col-xs-12">
                <a class="btn btn-outline-primary open-filter-margin btn-right float-end" (click)="openFilter()" translate>pages.common.list.filterButton
                    <i class="fa-solid fa-list"></i>
                </a>
            </div>
        </div>
        <app-data-table *ngIf="!classifiersLoading" [tableData]="tableData" [tableColumns]="tableColumns"
        [listActions]="actions" (edit)="onEdit($event)" (download)="onDownload($event)" (view)="onView($event)" (delete)="onDelete($event)"
        (sortChange)="onSortChange($event)">
        </app-data-table>
        <app-pagging [page]="searchData.Page" [pageSize]="searchData.PageSize" [total]="totalItem"
            (pageSizeChange)="onPageSizeChange($event)" (pageChange)="onPageChange($event)"></app-pagging>
    </div>
    </div>
    </ng-container>
</div>

<div *ngIf="loading" class="container-fluid">
  <app-loader type="list"></app-loader>
</div>
<div *ngIf="!loading">
  <div *ngIf="!staticFilter" class="bg-secondary px-3">
    <div [ngClass]="{'filter-closed': isFilterClosed, 'pt-3': !isFilterClosed}">
      <app-filter-buttons [title]="'pages.common.list.filter'" (clickClearFilter)="clearFilter()"
        (clickCloseFilter)="closeFilter()">
      </app-filter-buttons>
    </div>
    <div [ngClass]="{'filter-closed': isFilterClosed}">
      <div class="justify-content-center">
        <formio class="formio" #formio [form]="listingFormJson" [language]="language" [submission]="formSubmission"
          [options]="formOptions" (customEvent)="formioCustomEvent($event)" (change)="formioChange($event)"
          [refresh]="refreshForm" (render)="render()" (keydown.enter)="onFilter(true)"></formio>
      </div>
      <div *ngIf="showFilterButton" class="form-group pb-3 cp-font-weight-700">
        <a translate class="btn btn-primary" (click)="onFilter(true)">pages.common.list.searchButton
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="staticFilter">
    <div class="justify-content-center">
      <formio class="formio" #formio [form]="listingFormJson" [language]="language" [submission]="formSubmission"
        [options]="formOptions" (customEvent)="formioCustomEvent($event)" (change)="formioChange($event)"
        [refresh]="refreshForm" (render)="render()" (keydown.enter)="onFilter(true)"></formio>
    </div>
    <div *ngIf="showFilterButton" class="form-group pb-3 cp-font-weight-700">
      <a translate class="btn btn-primary" (click)="onFilter(true)">pages.common.list.searchButton
      </a>
    </div>
  </div>

  <div *ngIf="filterHeader || btnAddNewTitle" class="bg-transparent p-3">
    <div *ngIf="filterHeader" class="d-flex center">
      <h3 class="me-auto" translate>{{ filterHeader }}</h3>
      <div *ngIf="isFilterClosed && !staticFilter">
        <a class="btn btn-outline-primary" (click)="openFilter()" translate>pages.common.list.filterButton
          <i class="fa-solid fa-list"></i></a>
      </div>
    </div>
    <div class="d-flex justify-content-end cp-mt-20">
      <a *ngIf="btnAddNewTitle" translate class="btn btn-primary" id="list-create-btn" (click)="onCreate()"
        title="Add new record">{{ btnAddNewTitle }}</a>
    </div>
  </div>

  <ng-container>
    <div class="table-holder" #appDataTableDiv>
      <app-data-table *ngIf="!classifiersLoading" [tableData]="tableData" [tableColumns]="tableColumns"
        [listActions]="actions" (edit)="onEdit($event)" (view)="onView($event)" (delete)="onDelete($event)"
        (sortChange)="onSortChange($event)">
      </app-data-table>
    </div>
    <app-pagging [page]="searchData.Page" [pageSize]="searchData.PageSize" [total]="totalItem"
      (pageSizeChange)="onPageSizeChange($event)" (pageChange)="onPageChange($event)"></app-pagging>
  </ng-container>
</div>
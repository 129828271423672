<div class="w-100 cp-h-100 c-picture d-flex flex-row background-project-image" [ngStyle]="{'background-position': 'right', 'background-image':'url(' + inboxImage + ')'}">
    <div class="w-100">
        <div class="cp-ps-20 cp-pt-20">
            <a [routerLink]="" (click)="back()">
                <img src="assets/images/icons/Arrow_left.svg" class="c-icon-publicSearch cp-pb-2">
                <span class="publicSearch-back-color" translate>pages.common.back</span>
            </a>
        </div>
        
    </div> 
</div>
<div *ngIf="publicSearchListFormId" class="container-fluid cp-h-100 bg-secondary">
    <app-dynamic-list
        [module]="module"
        [listingMongoId]="publicSearchListFormId"
        [filterHeader]="'pages.common.publicSearch.header'"
        [actions]="[]">
    </app-dynamic-list>
</div>

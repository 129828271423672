import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { routesEnum } from 'projects/urp/src/app/enumerators/routesEnum';
import { DefaultParams } from 'projects/urp/src/app/models/filter.model';
import { TableColumn } from 'projects/urp/src/app/models/table-column.model';
import { BaseListComponent } from '../shared/base-list/base-list.component';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { KeycloakService } from 'keycloak-angular';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { FormsService } from 'projects/urp/src/app/services/forms.service/forms.service';
import { FieldValueGroup } from 'projects/urp/src/app/enumerators/classifier.enum';

@Component({
  selector: 'app-name-similarity-list',
  templateUrl: './name-similarity-list.component.html',
  styleUrls: ['./name-similarity-list.component.scss']
})
export class NameSimilarityListComponent extends BaseListComponent implements AfterViewInit {
  defaultSearchData = new DefaultParams('ValidFrom desc');
  actions: string[] = ['view'];

  tableColumns = [
    new TableColumn('ValidFrom', 'pages.myOffice.nameSimilarity.reservationDate', 'datetime'),
    new TableColumn('RawFieldValue', 'pages.myOffice.nameSimilarity.name'),
    new TableColumn('FieldValueGroupId', 'pages.myOffice.nameSimilarity.language', 'guid', 'FIELD_VALUE_GROUP'),
    new TableColumn('SimilarityScore', 'pages.myOffice.nameSimilarity.similarity', 'notTranslatable'),
    new TableColumn('NameStatusId', 'pages.myOffice.nameSimilarity.nameStatus', 'guid', 'NAME_STATUS')
  ];

  listUrl = routesEnum.nameSimilarity.url;
  @ViewChild('name') nameSearchBar: ElementRef;
  @ViewChildren('radio') radioButtons: QueryList<ElementRef>;
  fvgDefault = FieldValueGroup.Default;

  constructor(protected toastrService: ToastrServiceExt,
    protected modal: BsModalService,
    protected router: Router,
    protected readonly keycloak: KeycloakService,
    protected httpService: HttpService,
    protected location: Location,
    protected activatedroute: ActivatedRoute,
    protected formsService: FormsService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
    super(toastrService, modal, router, activatedroute, keycloak, location, httpService);
      this.apiUrl = `${this.environment.api.nameSimilarity}`;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.nameSearchBar.nativeElement.value = this.searchData['RawFieldValueLike'] ? this.searchData['RawFieldValueLike'] : '';
  }

  protected override loadClassifiers(): void {
    this.httpService.get(`${this.environment.api.classifier}/selectSearch?ClassifierDomainNaturalIds=FIELD-VALUE-GROUP&SortBy=RowOrder%20desc`).subscribe(res => {
      this.classifierData.FIELD_VALUE_GROUP = res;
      this.classifiersLoading = false;
      this.searchData['FieldValueGroupId'] = this.classifierData.FIELD_VALUE_GROUP[0]?.value;
      this.loadList();
    }, (err) => {
      this.toastrService.serverError(err);
    });

    this.httpService.get(`${this.environment.api.classifier}/selectSearch?ClassifierDomainNaturalIds=NAME-STATUS`).subscribe(res => {
      this.classifierData.NAME_STATUS = res;
      this.classifiersLoading = false;
    }, (err) => {
      this.toastrService.serverError(err);
    });
    
  }

  isChecked(value: string): boolean {
    return this.searchData['FieldValueGroupId'] === value;
  }

  public onSearchChange(value: string): void {    
    // TODO should use searchData but angular doesnt let use dynamic structure here somehow
    this.searchData['RawFieldValueLike'] = value;
    this.loading = true;
    this.loadList();
  }

  public onSearchLanguageChange(target: any): void {
    if (target.value === this.searchData['FieldValueGroupId']) {
      delete this.searchData['FieldValueGroupId'];
      target.checked = false;
    } else {
      this.searchData['FieldValueGroupId'] = target.value;
      this.radioButtons.forEach((radio: ElementRef) => {
        radio.nativeElement.checked = radio.nativeElement.value === target.value;
      });
    }
    this.loading = true;
    this.loadList();
  }

  protected override beforeLoadList(): void {
    const sortBy = this.searchData['SortBy'].split(' ');

    if (sortBy[0] === 'NameStatusId') {
      this.searchData['SortBy'] = `ParentFieldValueReservationId ${sortBy[1]}`;
    }
    super.beforeLoadList();
  }
}

import { Components } from "@formio/angular";
import { Formio } from "formiojs";
import { uniqueName } from 'formiojs/utils/utils';
import BMF from 'browser-md5-file';
import NativePromise from 'native-promise-only';
import download from 'downloadjs';

const File = Components.components.file;

export default class FileExtend extends File {
  constructor(component, options, data) {
    super(component, options, data);
  }

  getFile(fileInfo) {
    const { options = {} } = this.component;
    const { fileService } = this;
    if (!fileService) {
      return alert('File Service not provided');
    }
    if (this.component.privateDownload) {
      fileInfo.private = true;
    }
    fileService.downloadFile(fileInfo, options).then((file) => {
      if (file) {
        if (['base64', 'indexeddb'].includes(file.storage)) {
          download(file.url, file.originalName || file.name, file.type);
        }
        if (file.storage === 'url') {
          download(file.response, file.originalName || file.name, file.type);
          delete file.response;
        }
        else {
          window.open(file.url, '_blank');
        }
      }
    })
      .catch((response) => {
        // Is alert the best way to do this?
        // User is expecting an immediate notification due to attempting to download a file.
        alert(response);
      });
  }

  loadImage(fileInfo) {
    if (this.component.privateDownload) {
      fileInfo.private = true;
    }
    return this.fileService.downloadFile(fileInfo).then((result) => { 
      if (result.storage === 'url' && result.response) {
        return URL.createObjectURL(result.response);
      }
      return result.url
     });
  }

  upload(files) {
    // Only allow one upload if not multiple.
    if (!this.component.multiple) {
      if (this.statuses.length) {
        this.statuses = [];
      }
      files = Array.prototype.slice.call(files, 0, 1);
    }

    if (this.component.storage && files && files.length) {
      this.fileDropHidden = true;

      // files is not really an array and does not have a forEach method, so fake it.
      /* eslint-disable max-statements */
      Array.prototype.forEach.call(files, async (file) => {
        const bmf = new BMF();
        const hash = await new Promise((resolve, reject) => {
          bmf.md5(file, (err, md5) => {
            if (err) {
              return reject(err);
            }
            return resolve(md5);
          });
        });
        const fileName = uniqueName(file.name, this.component.fileNameTemplate, this.evalContext());
        const fileUpload = {
          originalName: file.name,
          name: fileName,
          size: file.size,
          status: 'info',
          message: this.t('Processing file. Please wait...'),
          hash
        };

        // Check if file with the same name is being uploaded
        const fileWithSameNameUploaded = this.dataValue.some(fileStatus => fileStatus.originalName === file.name);
        const fileWithSameNameUploadedWithError = this.statuses.findIndex(fileStatus =>
          fileStatus.originalName === file.name
          && fileStatus.status === 'error'
        );

        if (fileWithSameNameUploaded) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File with the same name is already uploaded');
        }

        if (fileWithSameNameUploadedWithError !== -1) {
          this.statuses.splice(fileWithSameNameUploadedWithError, 1);
          this.redraw();
        }

        // Check file pattern
        if (this.component.filePattern && !this.validatePattern(file, this.component.filePattern)) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File is the wrong type; it must be {{ pattern }}', {
            pattern: this.component.filePattern,
          });
        }

        // Check file minimum size
        if (this.component.fileMinSize && !this.validateMinSize(file, this.component.fileMinSize)) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File is too small; it must be at least {{ size }}', {
            size: this.component.fileMinSize,
          });
        }

        // Check file maximum size
        if (this.component.fileMaxSize && !this.validateMaxSize(file, this.component.fileMaxSize)) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File is too big; it must be at most {{ size }}', {
            size: this.component.fileMaxSize,
          });
        }

        // Get a unique name for this file to keep file collisions from occurring.
        const dir = this.interpolate(this.component.dir || '');
        const { fileService } = this;
        if (!fileService) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File Service not provided.');
        }

        this.statuses.push(fileUpload);
        this.redraw();

        if (fileUpload.status !== 'error') {
          if (this.component.privateDownload) {
            file.private = true;
          }
          const { storage, options = {} } = this.component;
          let url = this.interpolate(this.component.url, { file: fileUpload });
          if (url.startsWith('/')) {
            url = Formio.getBaseUrl() + url;
          }
          let groupKey = null;
          let groupPermissions = null;

          //Iterate through form components to find group resource if one exists
          this.root.everyComponent((element) => {
            if (element.component?.submissionAccess || element.component?.defaultPermission) {
              groupPermissions = !element.component.submissionAccess ? [
                {
                  type: element.component.defaultPermission,
                  roles: [],
                },
              ] : element.component.submissionAccess;

              groupPermissions.forEach((permission) => {
                groupKey = ['admin', 'write', 'create'].includes(permission.type) ? element.component.key : null;
              });
            }
          });

          const fileKey = this.component.fileKey || 'file';
          const groupResourceId = groupKey ? this.currentForm.submission.data[groupKey]._id : null;
          let processedFile = null;

          if (this.root.options.fileProcessor) {
            try {
              if (this.refs.fileProcessingLoader) {
                this.refs.fileProcessingLoader.style.display = 'block';
              }
              const fileProcessorHandler = fileProcessor(this.fileService, this.root.options.fileProcessor);
              processedFile = await fileProcessorHandler(file, this.component.properties);
            }
            catch (err) {
              fileUpload.status = 'error';
              fileUpload.message = this.t('File processing has been failed.');
              this.fileDropHidden = false;
              this.redraw();
              return;
            }
            finally {
              if (this.refs.fileProcessingLoader) {
                this.refs.fileProcessingLoader.style.display = 'none';
              }
            }
          }

          fileUpload.message = this.t('Starting upload.');
          this.redraw();

          const filePromise = fileService.uploadFile(
            storage,
            processedFile || file,
            fileName,
            dir,
            // Progress callback
            (evt) => {
              fileUpload.status = 'progress';
              fileUpload.progress = parseInt(100.0 * evt.loaded / evt.total);
              delete fileUpload.message;
              this.redraw();
            },
            url,
            options,
            fileKey,
            groupPermissions,
            groupResourceId,
            // Upload start callback
            () => {
              this.emit('fileUploadingStart', filePromise);
            },
            // Abort upload callback
            (abort) => this.abortUpload = abort,
          ).then((fileInfo) => {
            const index = this.statuses.indexOf(fileUpload);
            if (index !== -1) {
              this.statuses.splice(index, 1);
            }
            fileInfo.originalName = file.name;
            fileInfo.hash = fileUpload.hash;
            if (!this.hasValue()) {
              this.dataValue = [];
            }

            this.dataValue.push(fileInfo);
            this.fileDropHidden = false;
            this.redraw();
            this.triggerChange();
            this.emit('fileUploadingEnd', filePromise);
          })
            .catch((response) => {
              fileUpload.status = 'error';
              fileUpload.message = typeof response === 'string' ? response : response.toString();
              delete fileUpload.progress;
              this.fileDropHidden = false;
              this.redraw();
              this.emit('fileUploadingEnd', filePromise);
            });
        }
      });
    }
  }

  customAttach(element) {
    if (!this.builderMode && !this.previewMode && this.component.modalEdit) {
      const modalShouldBeOpened = this.componentModal ? this.componentModal.isOpened : false;
      const currentValue = modalShouldBeOpened ? this.componentModal.currentValue : this.dataValue;
      const openModalTemplate = this.componentModal && modalShouldBeOpened
        ? this.componentModal.openModalTemplate
        : null;
      this.componentModal = this.createComponentModal(element, modalShouldBeOpened, currentValue);
      this.setOpenModalElement(openModalTemplate);
    }

    this.attached = true;
    this.element = element;
    element.component = this;

    // If this already has an id, get it from the dom. If SSR, it could be different from the initiated id.
    if (this.element.id) {
      this.id = this.element.id;
      this.component.id = this.id;
    }

    this.loadRefs(element, {
      messageContainer: 'single',
      tooltip: 'multiple'
    });

    this.attachTooltips(this.refs.tooltip);

    // Attach logic.
    this.attachLogic();
    this.autofocus();

    // Allow global attach.
    this.hook('attachComponent', element, this);
    // Allow attach per component type.
    const type = this.component.type;
    if (type) {
      this.hook(`attach${type.charAt(0).toUpperCase() + type.substring(1, type.length)}`, element, this);
    }

    this.restoreFocus();

    this.addons.forEach((addon) => addon.attach(element));

    return NativePromise.resolve();
  }

  attach(element) {
    this.loadRefs(element, {
      fileDrop: 'single',
      fileBrowse: 'single',
      galleryButton: 'single',
      cameraButton: 'single',
      takePictureButton: 'single',
      toggleCameraMode: 'single',
      videoPlayer: 'single',
      fileLink: 'multiple',
      filePreviewInTabLink: 'multiple',
      fileDownloadLink: 'multiple',
      removeLink: 'multiple',
      fileStatusRemove: 'multiple',
      fileImage: 'multiple',
      fileType: 'multiple',
      fileProcessingLoader: 'single',
    });
    // Ensure we have an empty input refs. We need this for the setValue method to redraw the control when it is set.
    this.refs.input = [];
    const superAttach = this.customAttach(element);

    if (this.refs.fileDrop) {
      if (!this.statuses.length) {
        this.refs.fileDrop.removeAttribute('hidden');
      }
      const element = this;
      this.addEventListener(this.refs.fileDrop, 'dragover', function (event) {
        this.className = 'fileSelector fileDragOver';
        event.preventDefault();
      });
      this.addEventListener(this.refs.fileDrop, 'dragleave', function (event) {
        this.className = 'fileSelector';
        event.preventDefault();
      });
      this.addEventListener(this.refs.fileDrop, 'drop', function (event) {
        this.className = 'fileSelector';
        event.preventDefault();
        element.upload(event.dataTransfer.files);
      });
    }

    if (this.refs.fileBrowse) {
      this.addEventListener(this.refs.fileBrowse, 'click', (event) => {
        event.preventDefault();
        this.browseFiles(this.browseOptions)
          .then((files) => {
            this.upload(files);
          });
      });
    }

    this.refs.fileLink.forEach((fileLink, index) => {
      this.addEventListener(fileLink, 'click', (event) => {
        event.preventDefault();
        if (this.data.hasOwnProperty('defaultDownloadOnFilenameClick') && this.data.defaultDownloadOnFilenameClick) {
          this.getFile(this.dataValue[index]);
        }
        else {
          //TODO: Most probably all file types should be put in config, just not clear hot to get config there
          if ((index < this.dataValue.length) && !("docx,zip,bpmn,dmn,csv".split(',').includes(this.dataValue[index].name.split('.').pop()))) {
            let url = this.dataValue[index].url + '/preview';
            let fullUrl = url.startsWith('http') ? url : this.findFormioBaseUrl(this) + url;
            this.openFilePreviewPanel(fullUrl);
          } else if (index >= this.dataValue.length) {
              event.preventDefault();
              let i = index - this.dataValue.length;
              let imageInfo = Object.assign({}, this.dataValue[i]);
              let url = imageInfo.url;
              let fullUrl = url.startsWith('http') ? url : this.findFormioBaseUrl(this) + url;
              imageInfo.url = fullUrl;
              this.getFile(imageInfo);
          } else {
            this.getFile(this.dataValue[index]);
          }
        }
      });
    });

    this.refs.fileDownloadLink.forEach((fileDownloadLink, index) => {
      this.addEventListener(fileDownloadLink, 'click', (event) => {
        event.preventDefault();
        let i = index;
        if (index >= this.dataValue.length)
          i = index - this.dataValue.length;
        let imageInfo = Object.assign({}, this.dataValue[i]);
        let url = imageInfo.url;
        let fullUrl = url.startsWith('http') ? url : this.findFormioBaseUrl(this) + url;
        imageInfo.url = fullUrl;
        this.getFile(imageInfo);
      });
    });

    this.refs.filePreviewInTabLink.forEach((filePreviewInTabLink, index) => {
      this.addEventListener(filePreviewInTabLink, 'click', (event) => {
        event.preventDefault();
        let imageInfo = Object.assign({}, this.dataValue[index]);
        let url = imageInfo.url + '/preview';
        let fullUrl = url.startsWith('http') ? url : this.findFormioBaseUrl(this) + url;
        imageInfo.url = fullUrl;
        this.getFile(imageInfo);
      });
    });

    this.refs.removeLink.forEach((removeLink, index) => {
      this.addEventListener(removeLink, 'click', (event) => {
        const fileInfo = this.dataValue[index];

        this.deleteFile(fileInfo);
        event.preventDefault();
        this.splice(index);
        this.redraw();
      });
    });

    this.refs.fileStatusRemove.forEach((fileStatusRemove, index) => {
      this.addEventListener(fileStatusRemove, 'click', (event) => {
        event.preventDefault();
        if (this.abortUpload) {
          this.abortUpload();
        }
        this.statuses.splice(index, 1);
        this.redraw();
      });
    });

    if (this.refs.galleryButton && webViewCamera) {
      this.addEventListener(this.refs.galleryButton, 'click', (event) => {
        event.preventDefault();
        webViewCamera.getPicture((success) => {
          window.resolveLocalFileSystemURL(success, (fileEntry) => {
            fileEntry.file((file) => {
              const reader = new FileReader();
              reader.onloadend = (evt) => {
                const blob = new Blob([new Uint8Array(evt.target.result)], { type: file.type });
                blob.name = file.name;
                this.upload([blob]);
              };
              reader.readAsArrayBuffer(file);
            });
          }
          );
        }, (err) => {
          console.error(err);
        }, {
          sourceType: webViewCamera.PictureSourceType.PHOTOLIBRARY,
        });
      });
    }

    if (this.refs.cameraButton && webViewCamera) {
      this.addEventListener(this.refs.cameraButton, 'click', (event) => {
        event.preventDefault();
        webViewCamera.getPicture((success) => {
          window.resolveLocalFileSystemURL(success, (fileEntry) => {
            fileEntry.file((file) => {
              const reader = new FileReader();
              reader.onloadend = (evt) => {
                const blob = new Blob([new Uint8Array(evt.target.result)], { type: file.type });
                blob.name = file.name;
                this.upload([blob]);
              };
              reader.readAsArrayBuffer(file);
            });
          }
          );
        }, (err) => {
          console.error(err);
        }, {
          sourceType: webViewCamera.PictureSourceType.CAMERA,
          encodingType: webViewCamera.EncodingType.PNG,
          mediaType: webViewCamera.MediaType.PICTURE,
          saveToPhotoAlbum: true,
          correctOrientation: false,
        });
      });
    }

    if (this.refs.takePictureButton) {
      this.addEventListener(this.refs.takePictureButton, 'click', (event) => {
        event.preventDefault();
        this.takePicture();
      });
    }

    if (this.refs.toggleCameraMode) {
      this.addEventListener(this.refs.toggleCameraMode, 'click', (event) => {
        event.preventDefault();
        this.cameraMode = !this.cameraMode;
        this.redraw();
      });
    }

    this.refs.fileType.forEach((fileType, index) => {
      this.dataValue[index].fileType = this.dataValue[index].fileType || this.component.fileTypes[0].value;

      this.addEventListener(fileType, 'change', (event) => {
        event.preventDefault();

        const fileType = this.component.fileTypes.find((typeObj) => typeObj.value === event.target.value);

        this.dataValue[index].fileType = fileType.value;
      });
    });

    const fileService = this.fileService;
    if (fileService) {
      const loadingImages = [];
      this.filesReady = new NativePromise((resolve, reject) => {
        this.filesReadyResolve = resolve;
        this.filesReadyReject = reject;
      });
      this.refs.fileImage.forEach((image, index) => {
        loadingImages.push(this.loadImage(this.dataValue[index]).then((url) => (image.src = url)));
      });
      if (loadingImages.length) {
        NativePromise.all(loadingImages).then(() => {
          this.filesReadyResolve();
        }).catch(() => this.filesReadyReject());
      }
      else {
        this.filesReadyResolve();
      }
    }
    return superAttach;
  }

  findFormioBaseUrl(node) {
    if (node.hasOwnProperty('formio'))
      return node.formio.base;
    if (node.hasOwnProperty('parent'))
      return this.findFormioBaseUrl(node.parent);
    else
      return '';
  }

  openFilePreviewPanel(fileUrl) {
    let fileContainer = document.createElement('div');
    fileContainer.className = 'file-preview-container';

    let closeButton = document.createElement('div');
    closeButton.className = 'file-preview-close-button';
    closeButton.innerHTML = '&times;';
    closeButton.onclick = closeFilePreview;

    let fileIframe = document.createElement('iframe');
    fileIframe.className = 'file-preview-iframe';

    let xhr = new XMLHttpRequest();
    xhr.open('GET', fileUrl);
    xhr.onreadystatechange = function() {
      if (this.readyState === this.DONE) {
        if (this.status === 200) {
          // this.response is a Blob, because we set responseType above
          const dataUrl = URL.createObjectURL(this.response);
          fileIframe.src = dataUrl;
        } else {
          throw new Error('Could not load file');
        }
      }
    }
    xhr.responseType = 'blob';

    const token = Formio.getToken();
    const representationId = Formio.getUser()?.representationId;

    if (token) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    }
    if (representationId) {
      xhr.setRequestHeader('RepresentationId', representationId);
    }
    xhr.send();

    fileContainer.appendChild(closeButton);
    fileContainer.appendChild(fileIframe);

    document.body.appendChild(fileContainer);

    fileContainer.style.display = 'block';

    document.addEventListener('keydown', handleKeyPress);

    function closeFilePreview() {
      let fileContainer = document.querySelector('.file-preview-container');
      fileContainer.parentNode.removeChild(fileContainer);

      document.removeEventListener('keydown', handleKeyPress);
    }

    function handleKeyPress(event) {
      if (event.key === 'Escape') {
        closeFilePreview();
      }
    }
  }

}

Components.addComponent("file", FileExtend);
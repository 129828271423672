import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { routesEnum } from '../app/enumerators/routesEnum';

import { AuthGuard } from '../app/utility/auth.guard';
import { HomeComponent } from './components/layout/home/home.component';
import { UserListComponent } from './components/userManagement/user/user-list/user-list.component';
import { DomainListComponent } from './components/systemConfiguration/domain/domain-list/domain-list.component';
import { ClassifierListComponent } from './components/systemConfiguration/classifier/classifier-list/classifier-list.component';
import { InstitutionListComponent } from './components/systemConfiguration/institution/institution-list/institution-list.component';
import { RegisterListComponent } from './components/systemConfiguration/register/register-list/register-list.component';
import { RolesListComponent } from './components/userManagement/roles/roles-list/roles-list.component';
import { LanguageListComponent } from './components/systemConfiguration/language/language-list/language-list.component';
import { TranslationListComponent } from './components/systemConfiguration/translation/translation-list/translation-list.component';
import { MessageTemplateListComponent } from './components/systemConfiguration/messageTemplates/message-template-list/message-template-list.component';
import { MessageTemplateEditorComponent } from './components/systemConfiguration/messageTemplates/message-template-editor/message-template-editor.component';
import { MessageTemplateViewerComponent } from './components/systemConfiguration/messageTemplates/message-template-viewer/message-template-viewer.component';
import { UserFormComponent } from './components/userManagement/user/user-form/user-form.component';
import { RolesFormComponent } from './components/userManagement/roles/roles-form/roles-form.component';
import { BaseFormComponent } from './components/shared/base-form/base-form.component';
import { APPLICATION_ENDPOINT, APP_CONFIG, CLASSIFIER_ENDPOINT, DOMAIN_ENDPOINT, INSTITUTION_ENDPOINT, EXTERNAL_CLIENT_ENDPOINT, LANGUAGE_ENDPOINT, REGISTER_ENDPOINT, TRANSLATION_ENDPOINT, SERVICE_ENDPOINT, ID_GENERATION_CONFIG_ENDPOINT, CRON_JOB_CONFIGURATION_ENDPOINT, REGISTER_FUNCTIONALITY_ENDPOINT, REGISTRATION_OBJECT_ENDPOINT, ACCOUNT_TYPE_ENDPOINT, SERVICE_ENDPOINT_LIST_MERGING_RULES as SERVICE_ENDPOINT_CONFIGURATION, SERVICE_ENDPOINT_FILE_LIST_CONFIGS as SERVICE_ENDPOINT_WORKFLOW_CONFIGURATION, SERVICE_ENDPOINT_THUMBNAILS as SERVICE_ENDPOINT_OBJECT_LOAD_AND_CLEAN_UP_CONFIGURATION, SMS_PROVIDER_CONFIG_ENDPOINT, REPRESENTATIVE_ENDPOINT, ACCOUNT_ENDPOINT, NAME_SIMILARITY_ENDPOINT, NATIONAL_HOLIDAY_ENDPOINT } from './utility/tokens';
import { IConfig } from './models/config.interface';
import { RegisterComponent } from './components/register/register.component';
import { RegisterRoutingModule } from './routing/register-routing.module';
import { EventNotificationConfigComponent } from './components/systemConfiguration/eventNotificationConfiguration/eventNotificationConfigurationEditor/event-notification-config.component';
import { RegistrationObjectFormComponent } from './components/register/registration-object-form/registration-object-form.component';
import { NotificationViewerComponent } from './components/misc/notificationViewer/notification-viewer.component'
import { PushNotificationListComponent } from './components/misc/pushNotificationList/push-notification-list.component';
import { EventNotificationConfigListComponent } from './components/systemConfiguration/eventNotificationConfiguration/eventNotificationConfigList/event-notification-config-list.component';
import { MyOfficeComponent } from './components/myOffice/my-office/my-office.component';
import { SubmittedApplicationsComponent } from './components/myOffice/submitted-applications/submitted-applications.component';
import { IncompleteApplicationsComponent } from './components/myOffice/incomplete-applications/incomplete-applications.component';
import { RegisterServiceListComponent } from './components/systemConfiguration/register-service/register-service-list/register-service-list.component';
import { IdGenerationConfigListComponent } from './components/systemConfiguration/idGenerationConfigs/id-generation-config-list/id-generation-config-list.component';
import { NavigationGuardService } from './utility/navigation.guard';
import { CronJobConfigurationListComponent } from './components/systemConfiguration/cronJobConfiguration/cron-job-configuration-list.component';
import { RegisterFunctionalityListComponent } from './components/systemConfiguration/register-functionality/register-functionality-list.component';
import { RegisterFunctionalityRoutingModule } from './routing/register-functionality-routing.module';
import { MyOfficeRegistrationObjectsListComponent } from './components/myOffice/my-office-registration-objects-list/my-office-registration-objects-list.component';
import { ApplicationFormComponent } from './components/register/application-form/application-form.component';
import { ExternalClientListComponent } from './components/systemConfiguration/externalClients/external-client-list/external-client-list.component';
import { PrintoutQRCodeVerificationComponent } from './components/public/printout-qr-code-verification/printout-qr-code-verification.component';
import { RegistrationObjectHistoryListComponent } from './components/register/registration-object-history-list/registration-object-history-list.component';
import { RegistrationObjectHistoryFormComponent } from './components/register/registration-object-history-form/registration-object-history-form.component';
import { RegistrationObjectFileListComponent } from './components/register/registration-object-files-list/registration-object-files-list.component';
import { MyProfileComponent } from './components/userManagement/my-profile/my-profile.component';
import { AccountTypeListComponent } from './components/systemConfiguration/account-type/account-type-list/account-type-list.component';
import { AccountListComponent } from './components/userManagement/accounts/account-list/account-list.component';
import { RegisterServiceComponent } from './components/systemConfiguration/register-service/register-service-view/register-service.component';
import { RegisterServiceGeneralInfoComponent } from './components/systemConfiguration/register-service/register-service-general-info/register-service-general-info.component';
import { RegisterServiceWorkflowComponent } from './components/systemConfiguration/register-service/register-service-workflow/register-service-workflow.component';
import { RegisterServiceConfigurationComponent } from './components/systemConfiguration/register-service/register-service-configuration/register-service-configuration.component';
import { RegisterServiceWorkflowConfigurationComponent } from './components/systemConfiguration/register-service/register-service-workflow-configuration/register-service-workflow-configuration.component';
import { RegisterServiceObjectLoadAndCleanUpConfigurationComponent } from './components/systemConfiguration/register-service/register-service-object-load-and-clean-up-configuration/register-service-object-load-and-clean-up-configuration.component';
import { SMSProviderConfigComponent } from './components/systemConfiguration/sms-provider-config/sms-provider-config.component';
import { RepresentativeListComponent } from './components/userManagement/representatives/representative-list/representative-list.component';
import { MyOfficeRepresentativesListComponent } from './components/myOffice/my-office-representatives-list/my-office-representatives-list.component';
import { RepresentativeFormComponent } from './components/userManagement/representatives/representative-form/representative-form.component';
import { NameSimilarityListComponent } from './components/name-similarity-list/name-similarity-list.component';
import { PublicSearchComponent } from './components/public/public-search/public-search.component';
import { NationalHolidayListComponent } from './components/systemConfiguration/national-holiday/national-holiday-list/national-holiday-list.component';

const routes: Routes = [
  {
    path: routesEnum.home.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.home.label },
    children: [
      { path: '', component: HomeComponent },
      { path: routesEnum.profile.url, component: MyProfileComponent, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.profile.label, queryParams: true } },
      {
        path: routesEnum.userList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.userList.label },
        children: [
          { path: '', component: UserListComponent },
          { path: routesEnum.form.url, component: UserFormComponent, canActivate: [AuthGuard], data: { breadcrumb: 'breadcrumbs.user', queryParams: true, adminEdit: true } },
        ]
      },
      {
        path: routesEnum.roleList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.roleList.label },
        children: [
          { path: '', component: RolesListComponent },
          { path: routesEnum.form.url, component: RolesFormComponent, canActivate: [AuthGuard], data: { breadcrumb: 'breadcrumbs.role', queryParams: true } },
        ]
      },
      {
        path: routesEnum.accountList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.accountList.label },
        children: [
          { path: '', component: AccountListComponent },
          { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: {apiController: ACCOUNT_ENDPOINT, breadcrumb: 'breadcrumbs.account', queryParams: true } },
        ]
      },      
      {
        path: routesEnum.representativeList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.representativeList.label },
        children: [
          { path: '', component: RepresentativeListComponent },
          { path: routesEnum.form.url, component: RepresentativeFormComponent, canActivate: [AuthGuard], data: { breadcrumb: 'breadcrumbs.representative', queryParams: true } },
        ]
      },
      // { path: routesEnum.representationList.url, component: UserListComponent, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.representationList.label}},
      // { path: routesEnum.form.url, component: FormComponent, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.form.label, queryParams: true} },
      {
        path: routesEnum.domains.url, data: { breadcrumb: routesEnum.domains.label }, canActivate: [AuthGuard],
        children: [
          { path: '', component: DomainListComponent },
          { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: DOMAIN_ENDPOINT, breadcrumb: 'breadcrumbs.classifier', queryParams: true }, },
          {
            path: routesEnum.classifiers.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.classifiers.label, queryParams: true },
            children: [
              { path: '', component: ClassifierListComponent, },
              { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: CLASSIFIER_ENDPOINT, breadcrumb: 'breadcrumbs.record', queryParams: true }, },
            ]
          }
        ],
      },
      {
        path: routesEnum.institutionList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.institutionList.label },
        children: [
          { path: '', component: InstitutionListComponent },
          { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: INSTITUTION_ENDPOINT, breadcrumb: 'breadcrumbs.institution', queryParams: true } },
        ]
      },
      {
        path: routesEnum.registerFunctionalityList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.registerFunctionality.label },
        children: [
          { path: '', component: RegisterFunctionalityListComponent },
          { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: REGISTER_FUNCTIONALITY_ENDPOINT, breadcrumb: 'breadcrumbs.registerFunctionality', queryParams: true } },
        ]
      },
      {
        path: routesEnum.registerServiceList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.registerServiceList.label },
        children: [
          { path: '', component: RegisterServiceListComponent },
          { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: SERVICE_ENDPOINT, breadcrumb: 'breadcrumbs.registerService', queryParams: true } },
        ]
      },
      {
        path: routesEnum.registerList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.registerList.label },
        children: [
          { path: '', component: RegisterListComponent },
          { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: REGISTER_ENDPOINT, breadcrumb: 'breadcrumbs.register', queryParams: true } },
        ]
      },
      {
        path: routesEnum.languageList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.languageList.label },
        children: [
          { path: '', component: LanguageListComponent },
          { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: LANGUAGE_ENDPOINT, breadcrumb: 'breadcrumbs.language', queryParams: true } },
        ]
      },
      {
        path: routesEnum.translationList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.translationList.label },
        children: [
          { path: '', component: TranslationListComponent },
          { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: TRANSLATION_ENDPOINT, breadcrumb: 'breadcrumbs.translation', queryParams: true } },
        ]
      },
      {
        path: routesEnum.idGenerationConfigsList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.idGenerationConfigsList.label },
        children: [
          { path: '', component: IdGenerationConfigListComponent },
          { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: ID_GENERATION_CONFIG_ENDPOINT, breadcrumb: 'breadcrumbs.idGenerationConfig', queryParams: true } },
        ]
      },
      {
        path: routesEnum.externalClientsList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.externalClientsList.label },
        children: [
          { path: '', component: ExternalClientListComponent },
          { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: EXTERNAL_CLIENT_ENDPOINT, breadcrumb: 'breadcrumbs.externalKeycloakClient', queryParams: true } },
        ]
      },
      {
        path: routesEnum.messageTemplatesList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.messageTemplatesList.label },
        children: [
          { path: '', component: MessageTemplateListComponent }
        ]
      },
      {
        path: routesEnum.messageTemplatesEditor.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.messageTemplatesEditor.label },
        children: [
          { path: '', component: MessageTemplateEditorComponent }
        ]
      },
      {
        path: routesEnum.messageTemplatesViewer.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.messageTemplatesViewer.label },
        children: [
          { path: '', component: MessageTemplateViewerComponent }
        ]
      },
      {
        path: routesEnum.notificationViewer.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.notificationViewer.label },
        children: [
          { path: '', component: NotificationViewerComponent }
        ]
      },
      {
        path: routesEnum.pushNotificationList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.pushNotificationList.label },
        children: [
          { path: '', component: PushNotificationListComponent },
          { path: routesEnum.form.url, component: NotificationViewerComponent, canActivate: [AuthGuard], data: { breadcrumb: 'breadcrumbs.notification', queryParams: true } },

        ]
      },
      {
        path: routesEnum.register.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.register.label },
        children: [
          { path: '', component: RegisterComponent }]
      },
      {
        path: routesEnum.eventNotificationConfig.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.eventNotificationConfig.label },
        children: [
          { path: '', component: EventNotificationConfigComponent }]
      },
      {
        path: routesEnum.eventNotificationConfigList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.eventNotificationConfigList.label },
        children: [
          { path: '', component: EventNotificationConfigListComponent }]
      },
      {
        path: routesEnum.myOffice.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.myOffice.label },
        children: [
          { path: '', component: MyOfficeComponent },
          {
            path: routesEnum.submittedApplications.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.submittedApplications.label, queryParams: true },
            children: [
              { path: '', component: SubmittedApplicationsComponent, },
              { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: APPLICATION_ENDPOINT, breadcrumb: 'breadcrumbs.registerApplication', queryParams: true } }
            ]
          },
          {
            path: routesEnum.incompleteApplications.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.incompleteApplications.label, queryParams: true },
            children: [
              { path: '', component: IncompleteApplicationsComponent, },
              { path: routesEnum.form.url, component: ApplicationFormComponent, canActivate: [AuthGuard], data: { apiController: APPLICATION_ENDPOINT, breadcrumb: 'breadcrumbs.registerApplication', queryParams: true } }
            ]
          },
          {
            path: routesEnum.myOfficeRegistersList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.myOfficeRegistersList.label, queryParams: true },
            children: [
              { path: '', component: MyOfficeRegistrationObjectsListComponent, },
              { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: REGISTRATION_OBJECT_ENDPOINT, breadcrumb: 'breadcrumbs.myOfficeRegistrationObject', queryParams: true } }

            ]
          },
          {
            path: routesEnum.myOfficeRepresentativesList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.myOfficeRepresentativesList.label, queryParams: true },
            children: [
              { path: '', component: MyOfficeRepresentativesListComponent, },
              { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: REPRESENTATIVE_ENDPOINT, breadcrumb: 'breadcrumbs.MyOfficeRepresentative', queryParams: true } }
            ]
          },
          {
            path: routesEnum.registerHistoryList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.registerRegistersList.label },
            children: [
              { path: '', component: RegistrationObjectHistoryListComponent, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.registerHistoryList.label } },
              { path: routesEnum.form.url, component: RegistrationObjectHistoryFormComponent, canActivate: [AuthGuard], data: { apiController: REGISTER_ENDPOINT, breadcrumb: 'breadcrumbs.register', queryParams: true } },
            ]
          },
          {
            path: routesEnum.registerFileList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.registerRegistersList.label },
            children: [
              { path: '', component: RegistrationObjectFileListComponent, canActivate: [AuthGuard], data: { apiController: REGISTER_ENDPOINT, breadcrumb: routesEnum.registerFileList.label } }
            ]
          }
        ]
      },
      {
        path: routesEnum.nameSimilarity.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.nameSimilarity.label, queryParams: true },
        children: [
          { path: '', component: NameSimilarityListComponent, },
          { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: NAME_SIMILARITY_ENDPOINT, breadcrumb: 'breadcrumbs.nameSimilarity', queryParams: true } }
        ]
      },
      {
        path: routesEnum.cronJobConfiguration.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.cronJobConfiguration.label },
        children: [
          { path: '', component: CronJobConfigurationListComponent },
          { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: CRON_JOB_CONFIGURATION_ENDPOINT, breadcrumb: 'breadcrumbs.cronJobConfiguration', queryParams: true } },
        ]
      },
      {
        path: routesEnum.accountTypeList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.accountTypeList.label },
        children: [
          { path: '', component: AccountTypeListComponent },
          { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: ACCOUNT_TYPE_ENDPOINT, breadcrumb: 'breadcrumbs.accountTypeList', queryParams: true } },
        ]
      },
      {
        path: routesEnum.smsProvidersConfigList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.smsProvidersConfigList.label },
        children: [
          { path: '', component: SMSProviderConfigComponent },
          { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: SMS_PROVIDER_CONFIG_ENDPOINT, breadcrumb: 'breadcrumbs.smsProviderConfigList', queryParams: true } },
        ]
      },
      {
        path: routesEnum.nationalHolidayList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.nationalHolidayList.label },
        children: [
          { path: '', component: NationalHolidayListComponent },
          { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { apiController: NATIONAL_HOLIDAY_ENDPOINT, breadcrumb: 'breadcrumbs.nationalHolidayList', queryParams: true } },
        ]
      }
    ]
  },
  { path: routesEnum.printoutQRCodeVerification.url, component: PrintoutQRCodeVerificationComponent, data: { breadcrumb: routesEnum.printoutQRCodeVerification.label, queryParams: true } },
  {
    path: routesEnum.registerService.url, component: RegisterServiceComponent, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.registerService.label },
    children: [
      { path: '', redirectTo: routesEnum.registerServiceGeneral.url, pathMatch: 'full' },
      { path: routesEnum.registerServiceGeneral.url, component: RegisterServiceGeneralInfoComponent, canActivate: [AuthGuard], data: { apiController: SERVICE_ENDPOINT, breadcrumb: 'breadcrumbs.registerServiceGeneralInfo', queryParams: true } },
      { path: routesEnum.registerServiceConfiguration.url, component: RegisterServiceConfigurationComponent, canActivate: [AuthGuard], data: { apiController: SERVICE_ENDPOINT_CONFIGURATION, breadcrumb: 'breadcrumbs.registerServiceConfiguration', queryParams: true } },
      { path: routesEnum.registerServiceWorkflowConfiguration.url, component: RegisterServiceWorkflowConfigurationComponent, canActivate: [AuthGuard], data: { apiController: SERVICE_ENDPOINT_WORKFLOW_CONFIGURATION, breadcrumb: 'breadcrumbs.registerServiceWorkflowConfiguration', queryParams: true } },
      { path: routesEnum.registerServiceRegistrationObjectLoadCleanUpConfiguration.url, component: RegisterServiceObjectLoadAndCleanUpConfigurationComponent, canActivate: [AuthGuard], data: { apiController: SERVICE_ENDPOINT_OBJECT_LOAD_AND_CLEAN_UP_CONFIGURATION, breadcrumb: 'breadcrumbs.registerServiceThumbnails', queryParams: true } },
      { path: routesEnum.form.url, component: BaseFormComponent, canActivate: [AuthGuard], data: { breadcrumb: 'breadcrumbs.registerService', queryParams: true } },
    ]
  },
  {
    path: routesEnum.publicSearch.url, data: { breadcrumb: routesEnum.publicSearch.label },
    children: [
      { path: '', component: PublicSearchComponent, data: { breadcrumb: 'breadcrumbs.publicSearch', queryParams: true } }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { useHash: true, paramsInheritanceStrategy: 'always' }),
    RegisterRoutingModule],
  providers: [
    {
      provide: DOMAIN_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.domain
    },
    {
      provide: CLASSIFIER_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.classifier
    },
    {
      provide: INSTITUTION_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.institution
    },
    {
      provide: REGISTER_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.registers
    },
    {
      provide: LANGUAGE_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.language
    },
    {
      provide: TRANSLATION_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.translation
    },
    {
      provide: ID_GENERATION_CONFIG_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.idGenerationConfigs
    },
    {
      provide: EXTERNAL_CLIENT_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.externalClients
    },
    {
      provide: APPLICATION_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.application
    },
    {
      provide: SERVICE_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.service
    },
    {
      provide: CRON_JOB_CONFIGURATION_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.cronJobConfigurations
    },
    {
      provide: REGISTER_FUNCTIONALITY_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.registerFunctionalities
    },
    {
      provide: ACCOUNT_TYPE_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.accountTypes
    },
    {
      provide: REGISTER_FUNCTIONALITY_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.registerFunctionalities
    },
    {
      provide: SERVICE_ENDPOINT_CONFIGURATION,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.serviceConfiguration
    },
    {
      provide: SERVICE_ENDPOINT_WORKFLOW_CONFIGURATION,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.serviceWorkflowConfiguration
    },
    {
      provide: SERVICE_ENDPOINT_OBJECT_LOAD_AND_CLEAN_UP_CONFIGURATION,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.serviceObjectLoadAndCleanUpConfiguration
    },
    {
      provide: SMS_PROVIDER_CONFIG_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.smsProviderConfigs
    },
    {
      provide: REPRESENTATIVE_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.umRepresentatives
    },
    {
      provide: ACCOUNT_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.umAccounts
    },
    {
      provide: NAME_SIMILARITY_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.nameSimilarity
    },
    {
      provide: NATIONAL_HOLIDAY_ENDPOINT,
      deps: [APP_CONFIG],
      useFactory: (config: IConfig) => config.api.nationalHolidays
    }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

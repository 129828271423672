export enum formEnum {
    internalUserForm = 'internal_user',
    externalUserForm = 'external_user',
    domainForm = 'domain',
    classifierForm = 'classifier',
    institutionForm = 'institution',
    registerForm = 'register',
    roleForm = 'role',
    languageForm = 'language',
    translationForm = 'translation',
    rorApplicationForm = 'ror_application',
    idGenerationConfigForm = 'id_generation_config',
    rorUpdate = 'ror_update',
    rorAppUpdate = 'ror_app_update',
    rorDeactivate = 'ror_deactivate',
    roaApplicationView = 'RoA_viewApplication',
    roaRegisterView = 'RoA_viewRegister',
    mdBusregApplicationView = 'MD_form',
    registerServiceForm = 'register_service',
    registerServiceGeneralForm = 'register_service_general',
    registerServiceWorkflowForm = 'register_service_workflow',
    registerServiceListMergingRulesForm = 'register_service_listMergingRules',
    registerServiceWorkflowConfigurationForm = 'register_service_workflowConfiguration',
    registerServiceObjectLoadAndConfigurationForm = 'register_service_objectLoadAndCleanUpConfiguration',
    registerServiceConfigurationForm = 'register_service_configuration',
    cronJobConfigurationForm = 'cron_job_configuration',
    registerFunctionalityListForm = 'register_functionality_list',
    registerFunctionalityForm = 'register_functionality',
    externalClientForm = 'external_client',
    accountTypeForm = 'account_type',
    accountForm = 'account',
    representativeForm = 'representative',
    internalRepresentativeForm = 'internal_representative',
    smsProviderConfigForm = 'sms_provider_config',
    nationalHolidayForm = 'national_holiday',
}
